<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>

      <div class="form-group mt-3" v-if="car && onProgress === false">
        <input type="radio" class="btn-check" name="options-outlined" id="owner-client"
               @input="form.owner_type = 'client'" autocomplete="off" :checked="form.owner_type === 'client'">
        <label class="btn btn-outline-primary" for="owner-client">Авто клиента</label>

        <input type="radio" class="btn-check" name="options-outlined" id="owner-company"
               @input="() => {
                 form.owner_type = 'company';
                 form.location = 'showroom';
               }" autocomplete="off" :checked="form.owner_type === 'company'">
        <label class="btn btn-outline-primary" for="owner-company">Авто компании</label>

        <input type="radio" class="btn-check" name="options-outlined" id="owner-exchange"
               @input="() => {
                 form.owner_type = 'exchange';
                 form.location = 'owner';
               }" autocomplete="off" :checked="form.owner_type === 'exchange'">
        <label class="btn btn-outline-primary" for="owner-exchange">Обмен/выкуп</label>
      </div>
    </div>

    <div class="box-body">
      <transition name="fade">
        <form @keydown="form.onKeydown($event)" @submit.prevent="editCar" v-if="car && onProgress === false">

          <div class="form-group">
            <div class="row">
              <div class="col-12">
                <label class="font-medium font-small">
                  Тип договора
                </label>
                <div class="form-group mt-1">
                  <input type="radio"
                         class="btn-check"
                         name="options-outlined-contract_type"
                         id="contract_type-free"
                         @input="form.contract_type = 'free'"
                         autocomplete="off"
                         :checked="form.contract_type === 'free'">
                  <label class="btn btn-outline-success" for="contract_type-free">
                    Бесплатно
                  </label>
<!--                  <input type="radio"-->
<!--                         class="btn-check"-->
<!--                         name="options-outlined-contract_type"-->
<!--                         id="contract_type-fee"-->
<!--                         @input="form.contract_type = 'fee'"-->
<!--                         autocomplete="off"-->
<!--                         :checked="form.contract_type === 'fee'">-->
<!--                  <label class="btn btn-outline-success" for="contract_type-fee">-->
<!--                    Абонплата-->
<!--                  </label>-->
                  <input type="radio" class="btn-check"
                         name="options-outlined-contract_type"
                         id="contract_type-compensation"
                         @input="form.contract_type = 'compensation'"
                         autocomplete="off"
                         :checked="form.contract_type === 'compensation'">
                  <label class="btn btn-outline-success" for="contract_type-compensation">
                    Компенсация
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group" v-if="['client', 'exchange'].includes(form.owner_type) || form.client_id">
            <div class="row">
              <h5>Данные владельца</h5>

              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('client_id') }">
                <multiselect :allow-empty="true"
                             :options="clients"
                             :searchable="true"
                             :show-labels="true"
                             id="client_id"
                             @input="() => {
                               form.client_id = client ? client.id : null;
                               form.client_name = client ? client.name : null;
                               form.client_phone = client ? client.phone.toString() : '';
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Владелец"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Владелец"
                             track-by="id"
                             v-model="client">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.name }}, {{ props.option.phone }}
                    <span v-if="props.option.address">
                      , {{ props.option.address }}
                    </span>
                    <span v-if="props.option.driver_license">
                      , {{ props.option.driver_license}}
                    </span>
                  </template>
<!--                  <template slot="option" slot-scope="props">-->
<!--                    {{ props.option.name }}, <span class="text-muted">{{ props.option.phone }}</span>-->
<!--                  </template>-->
                </multiselect>
                <HasError :form="form" field="client_id"/>
              </div>

              <div class="col-6">
                <div class="row">
                  <div class="col-md-6 col-sm-12" v-bind:class="{ 'has-error': form.errors.has('client_name') }">
                    <input class="form-control"
                           :disabled="Boolean(form.client_id)"
                           id="client_name"
                           name="client_name"
                           placeholder="ФИО владельца"
                           title="ФИО владельца"
                           type="text"
                           v-model="form.client_name">
                    <HasError :form="form" field="client_name"/>
                  </div>

                  <div class="col-md-6 col-sm-12" v-bind:class="{ 'has-error': form.errors.has('client_phone') }">
                    <vue-tel-input id="client_phone"
                                   :disabled="Boolean(form.client_id)"
                                   name="client_phone"
                                   v-bind='phone_config'
                                   v-model="form.client_phone">
                    </vue-tel-input>
                    <HasError :form="form" field="client_phone"/>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-md-12">
                <label class="font-medium font-small">
                  Местоположение
                </label>
                <div class="form-group mt-1">
                  <input type="radio" class="btn-check" name="options-outlined" id="location-showroom"
                         @input="form.location = 'showroom'" autocomplete="off" :checked="form.location === 'showroom'">
                  <label class="btn btn-outline-success" for="location-showroom">Автосалон</label>
                  <input type="radio" class="btn-check" name="options-outlined" id="location-owner"
                         @input="form.location = 'owner'" autocomplete="off" :checked="form.location === 'owner'">
                  <label class="btn btn-outline-success" for="location-owner">У владельца</label>
                </div>
              </div>

              <div class="col-lg-3 col-md-12">
                <div class="form-group mt-3" v-bind:class="{ 'has-error': form.errors.has('client_address') }">
                  <input class="form-control"
                         id="client_address"
                         name="client_address"
                         placeholder="Адрес владельца"
                         title="Адрес владельца"
                         type="text"
                         v-model="form.client_address">
                  <HasError :form="form" field="client_address" />
                </div>
              </div>

              <div class="col-lg-3 col-md-12">
                <div class="form-group mt-3" v-bind:class="{ 'has-error': form.errors.has('client_driver_license') }">
                  <input class="form-control"
                         id="client_driver_license"
                         name="client_driver_license"
                         placeholder="Удостоверение водителя / ИНН"
                         title="Удостоверение водителя / ИНН"
                         type="text"
                         v-model="form.client_driver_license">
                  <HasError :form="form" field="client_driver_license" />
                </div>
              </div>
            </div>
            <hr style="margin: 0.5rem 0;"/>

          </div>

          <div class="form-group">
            <div class="row">
              <h5>Данные авто</h5>

              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('mark_id') }">
                <label class="font-medium font-small">
                  Марка
                </label>
                <multiselect :allow-empty="false"
                             :options="marks"
                             :searchable="true"
                             @input="() => {
                               form.mark_id = mark ? mark.id : null;
                             }"
                             @select="getCarModels"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Марка"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Марка"
                             track-by="id"
                             v-model="mark">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="mark_id"/>
              </div>

              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('model_id') }">
                <label class="font-medium font-small">
                  Модель
                </label>
                <multiselect :allow-empty="false"
                             :disabled="!models || models.length === 0"
                             :options="models"
                             :searchable="true"
                             @input="() => {
                               form.model_id = model ? model.id : null;
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Модель"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Модель"
                             track-by="id"
                             v-model="model">
                  <template slot="placeholder">
                    <i class="fas font-scale fa-circle-notch fa-spin text-blue"
                       v-if="onProgressCarModels === true"/>
                    Модель
                  </template>
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="model_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('branch_id') }">
                <label class="font-medium font-small">
                  Филиал
                </label>
                <multiselect :allow-empty="false"
                             :options="branches"
                             :searchable="false"
                             :show-labels="true"
                             @input="() => {
                               form.branch_id = branch ? branch.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Филиал"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Филиал"
                             track-by="id"
                             v-model="branch">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                  <template slot="singleLabel" slot-scope="props">
                    {{ props.option.title }}, {{ props.option.address }}
                  </template>
                  <template slot="option" slot-scope="props">
                    {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                  </template>
                </multiselect>
                <HasError :form="form" field="branch_id"/>
              </div>
              <div class="col-lg-3 col-md-6"
                   v-bind:class="{ 'has-error': form.errors.has('type_id') }">
                <label class="font-medium font-small">
                  Кузов
                </label>
                <multiselect :allow-empty="false"
                             :options="types"
                             :searchable="false"
                             @input="() => {
                               form.type_id = type ? type.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Кузов"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Кузов"
                             track-by="id"
                             v-model="type">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="type_id"/>
              </div>
              <div class="col-lg-3 col-md-6"
                   v-bind:class="{ 'has-error': form.errors.has('origin_id') }">
                <label class="font-medium font-small">
                  Происхождение
                </label>
                <multiselect :allow-empty="false"
                             :options="origins"
                             :searchable="false"
                             @input="() => {
                               form.origin_id = origin ? origin.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Происхождение"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Происхождение"
                             track-by="id"
                             v-model="origin">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="origin_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('reg_number') }">
                <label class="font-medium font-small">
                  Гос. номер
                </label>
                <label class="full-width" for="reg_number">
                  <input class="form-control"
                         id="reg_number"
                         name="reg_number"
                         placeholder="Гос. номер"
                         required
                         title="Гос. номер"
                         type="text"
                         v-model="form.reg_number">
                  <HasError :form="form" field="reg_number"/>
                </label>
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('year') }">
                <label class="font-medium font-small">
                  Год выпуска
                </label>
                <label class="full-width" for="year">
                  <input :max="2024"
                         :min="1900"
                         class="form-control"
                         id="year"
                         name="year"
                         placeholder="Год выпуска"
                         required
                         title="Год выпуска"
                         type="number"
                         v-model="form.year">
                  <HasError :form="form" field="year"/>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('vin_code') }">
                <label class="font-medium font-small">
                  VIN-код
                </label>
                <label class="full-width" for="vin_code">
                  <input :max="17"
                         class="form-control"
                         id="vin_code"
                         name="vin_code"
                         placeholder="VIN-код"
                         required
                         title="VIN-код"
                         type="text"
                         v-model="form.vin_code">
                  <HasError :form="form" field="vin_code"/>
                </label>
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('gearbox_id') }">
                <label class="font-medium font-small">
                  КПП
                </label>
                <multiselect :allow-empty="false"
                             :options="gearboxes"
                             :searchable="false"
                             @input="() => {
                               form.gearbox_id = gearbox ? gearbox.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="КПП"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="КПП"
                             track-by="id"
                             v-model="gearbox">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="gearbox_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('fuel_id') }">
                <label class="font-medium font-small">
                  Топливо
                </label>
                <multiselect :allow-empty="false"
                             :options="fuels"
                             :searchable="false"
                             @input="() => {
                               form.fuel_id = fuel ? fuel.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Топливо"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Топливо"
                             track-by="id"
                             v-model="fuel">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="fuel_id"/>
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('color_id') }">
                <label class="font-medium font-small">
                  Цвет
                </label>
                <multiselect :allow-empty="false"
                             :options="colors"
                             :searchable="false"
                             @input="() => {
                               form.color_id = color ? color.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Цвет"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Цвет"
                             track-by="id"
                             v-model="color">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="color_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('engine') }">
                <label class="font-medium font-small">
                  Объем двигателя
                </label>
                <label class="full-width" for="engine">
                  <input :min="0.1"
                         class="form-control"
                         id="engine"
                         name="engine"
                         placeholder="Объем двигателя"
                         required
                         step=".1"
                         title="Объем двигателя"
                         type="number"
                         v-model="form.engine">
                  <HasError :form="form" field="engine"/>
                </label>
              </div>
              <div class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('capacity') }">
                <label class="font-medium font-small">
                  Кол-во мест
                </label>
                <label class="full-width" for="capacity">
                  <input :max="30"
                         :min="1"
                         class="form-control"
                         id="capacity"
                         name="capacity"
                         placeholder="Кол-во мест"
                         required
                         title="Кол-во мест"
                         type="number"
                         v-model="form.capacity">
                  <HasError :form="form" field="capacity"/>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('race') }">
                <label class="font-medium font-small">
                  Пробег
                </label>
                <label class="full-width" for="race">
                  <input :max="9999999"
                         :min="1"
                         class="form-control"
                         id="race"
                         name="race"
                         placeholder="Пробег"
                         required
                         title="Пробег"
                         type="number"
                         v-model="form.race">
                  <HasError :form="form" field="race"/>
                </label>
              </div>
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('transmission_id') }">
                <label class="font-medium font-small">
                  Привод
                </label>
                <multiselect :allow-empty="false"
                             :options="transmissions"
                             :searchable="false"
                             @input="() => {
                               form.transmission_id = transmission ? transmission.id : null;
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Привод"
                             required
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Привод"
                             track-by="id"
                             v-model="transmission">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
                <HasError :form="form" field="transmission_id"/>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('price') }">
                <label class="font-medium font-small">
                  Стоимость
                </label>
                <label class="full-width" for="price">
                  <input :max="999999999"
                         :min="1"
                         class="form-control"
                         id="price"
                         name="price"
                         placeholder="Стоимость"
                         required
                         title="Стоимость"
                         type="number"
                         v-model="form.price">
                  <HasError :form="form" field="price"/>
                </label>
              </div>

              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('old_price') }">
                <label class="font-medium font-small">
                  Старая стоимость
                </label>
                <label class="full-width" for="old_price">
                  <input :max="999999999"
                         class="form-control"
                         id="old_price"
                         name="old_price"
                         placeholder="Старая стоимость"
                         title="Старая стоимость"
                         type="number"
                         v-model="form.old_price">
                  <HasError :form="form" field="old_price"/>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div v-if="form.owner_type === 'client'" class="col-6"
                   v-bind:class="{ 'has-error': form.errors.has('price_for_owner') }">
                <label class="font-medium font-small">
                  Стоимость в руки
                </label>
                <label class="full-width" for="price_for_owner">
                  <input :max="999999999"
                         class="form-control"
                         id="price_for_owner"
                         name="price_for_owner"
                         placeholder="Стоимость в руки"
                         title="Стоимость в руки"
                         type="number"
                         v-model="form.price_for_owner">
                  <HasError :form="form" field="price_for_owner"/>
                </label>
              </div>
              <div v-else class="col-6"></div>

              <div class="col-6">
                <div class="form-control mt-4">
                  <input class="pointer"
                         id="is_top_price"
                         :checked="form.is_top_price"
                         style="transform: scale(1.2);"
                         type="checkbox"
                         v-model="form.is_top_price">
                  <label for="is_top_price" class="margin-l-5 padding-l-10">Суперцена</label>

                  <br class="d-xxl-none d-xl-none d-lg-none d-md-none"/>

                  <input class="pointer"
                         id="is_checked"
                         :checked="form.is_checked"
                         style="transform: scale(1.2);"
                         type="checkbox"
                         v-model="form.is_checked">
                  <label for="is_checked" class="margin-l-5 padding-l-10">Проверен на СТО</label>

                  <br class="d-xxl-none d-xl-none d-lg-none d-md-none"/>

                  <template v-if="[1, 7, 8].includes($store.getters['auth/authUser'].role.id)">
                    <input class="pointer"
                           id="is_our"
                           :checked="form.is_our"
                           style="transform: scale(1.2);"
                           type="checkbox"
                           v-model="form.is_our">
                    <label for="is_our" class="margin-l-5 padding-l-10">Выкупленное авто</label>

                    <br class="d-xxl-none d-xl-none d-lg-none d-md-none"/>
                  </template>

                  <input class="pointer"
                         id="is_new"
                         :checked="form.is_new"
                         style="transform: scale(1.2);"
                         type="checkbox"
                         v-model="form.is_new">
                  <label for="is_new" class="margin-l-5 padding-l-10">Новый</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6" v-if="form.owner_type === 'client'"
                   v-bind:class="{ 'has-error': form.errors.has('credit_percent') }">
                <label class="full-width" for="credit_percent">
                  <small>Процент компании</small>
                  <input :max="100"
                         :min="1"
                         class="form-control"
                         id="credit_percent"
                         name="credit_percent"
                         placeholder="Процент компании"
                         required
                         title="Стоимость"
                         type="number"
                         v-model="form.credit_percent">
                  <HasError :form="form" field="credit_percent"/>
                </label>
              </div>
              <div v-else class="col-6" v-bind:class="{ 'has-error': form.errors.has('minimum_price') }">
                <label class="font-medium font-small">
                  Минимальная стоимость
                </label>
                <label class="full-width" for="minimum_price">
                  <input :max="999999999"
                         class="form-control"
                         id="minimum_price"
                         name="minimum_price"
                         placeholder="Минимальная стоимость"
                         title="Минимальная стоимость"
                         type="number"
                         v-model="form.minimum_price">
                  <HasError :form="form" field="minimum_price"/>
                </label>
              </div>

              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('tech_passport') }">
                <label class="full-width" for="tech_passport">
                  <small>Тех. паспорт</small>
                  <input class="form-control"
                         id="tech_passport"
                         name="tech_passport"
                         placeholder="Тех. паспорт"
                         title="Тех. паспорт"
                         type="text"
                         v-model="form.tech_passport">
                  <HasError :form="form" field="tech_passport"/>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('damage_link') }">
                <label class="full-width" for="damage_link">
                  <small>Ссылка на фото повреждений</small>
                  <input class="form-control"
                         id="damage_link"
                         name="damage_link"
                         placeholder="Ссылка на фото повреждений"
                         title="Ссылка на фото повреждений"
                         type="text"
                         v-model="form.damage_link">
                  <HasError :form="form" field="damage_link"/>
                </label>
              </div>
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('old_ads_link') }">
                <label class="full-width" for="old_ads_link">
                  <small>Ссылка на старую рекламу</small>
                  <input class="form-control"
                         id="old_ads_link"
                         name="old_ads_link"
                         placeholder="Ссылка на старую рекламу"
                         title="Ссылка на старую рекламу"
                         type="text"
                         v-model="form.old_ads_link">
                  <HasError :form="form" field="old_ads_link"/>
                </label>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('autoria_id') }">
                <label class="full-width" for="autoria_id">
                  <small>AutoRIA ID</small>
                  <input class="form-control"
                         id="autoria_id"
                         name="autoria_id"
                         placeholder="AutoRIA ID"
                         title="AutoRIA ID"
                         type="text"
                         v-model="form.autoria_id">
                  <HasError :form="form" field="autoria_id"/>
                </label>
              </div>
              <div class="col-6" v-bind:class="{ 'has-error': form.errors.has('olx_id') }">
                <label class="full-width" for="olx_id">
                  <small>OLX ID</small>
                  <input class="form-control"
                         id="olx_id"
                         name="olx_id"
                         placeholder="OLX ID"
                         title="OLX ID"
                         type="text"
                         v-model="form.olx_id">
                  <HasError :form="form" field="olx_id"/>
                </label>
              </div>
            </div>
          </div>

          <br/>

          <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные."/>

          <Button :disabled="!validateData || form.busy"
                  :form="form"
                  :loading="form.busy"
                  class="btn btn-lg btn-warning pull-right"
                  style="min-width: 100px;">
            <i class="fa fa-edit"></i>
          </Button>
        </form>
        <loading-processing v-else></loading-processing>
      </transition>
    </div>
  </div>
</template>

<script>
import Form from 'vform';
import Multiselect from 'vue-multiselect';
import {VueTelInput} from 'vue-tel-input';
import phone_config from './../../assets/data/vue-tel-input-config.json';
import BranchService from '../../services/BranchService';

Form.axios = API.apiClient;

export default {
  name: 'edit',

  components: {
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
    VueTelInput,
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  props: {
    carId: {
      type: [String, Number],
      required: true,
    },
  },

  data: () => ({
    page_title: 'Изменить авто',

    form: null,

    car: null,
    marks: [],
    mark: null,
    models: [],
    model: null,
    branches: [],
    branch: null,
    types: [],
    type: null,
    origins: [],
    origin: null,
    gearboxes: [],
    gearbox: null,
    fuels: [],
    fuel: null,
    colors: [],
    color: null,
    transmissions: [],
    transmission: null,

    clients: [],
    client: null,

    car_relations: [
      'mark',
      'model',
      'branch',
      'type',
      'origin',
      'specification.gearbox',
      'specification.fuel',
      'specification.color',
      'specification.transmission',
      'links',
      'owner',
    ],

    phone_config: phone_config,

    onProgress: true,
    onProgressCarModels: false,
  }),

  async created() {
    try {
      const [
        car_response,
        marks_response,
        types_response,
        origins_response,
        gearboxes_response,
        fuels_response,
        colors_response,
        transmissions_response,
        clients_response,
      ] = await axios.all([
        API.apiClient.get('/car/' + this.carId, {
          params: {
            relations: this.car_relations,
          },
        }),
        API.apiClient.get('/cars/marks', {
          params: {
            is_paginated: 0,
            sorting: 'asc',
            sort_column: 'name'
          }
        }),
        API.apiClient.get('/cars/types'),
        API.apiClient.get('/cars/origins'),
        API.apiClient.get('/cars/gearboxes'),
        API.apiClient.get('/cars/fuels'),
        API.apiClient.get('/cars/colors'),
        API.apiClient.get('/cars/transmissions'),
        API.apiClient.get('/clients', {
          params: {
            "is_list_load": 1
          },
        }),
      ]);

      this.car = car_response.data;
      this.marks = marks_response.data;
      this.branches = await BranchService.getItems();
      this.types = types_response.data;
      this.origins = origins_response.data;
      this.gearboxes = gearboxes_response.data;
      this.fuels = fuels_response.data;
      this.colors = colors_response.data;
      this.transmissions = transmissions_response.data;

      this.clients = clients_response.data;

      this.fillCarForm();
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  computed: {
    validateData: function () {
      return this.form.mark_id && this.form.model_id && this.form.branch_id && this.form.type_id &&
          this.form.origin_id && this.form.transmission_id && this.form.reg_number && this.form.year &&
          this.form.vin_code && this.form.gearbox_id && this.form.fuel_id && this.form.color_id &&
          this.form.engine && this.form.capacity && this.form.race;
    },
  },

  methods: {
    fillCarForm() {
      try {
        this.getCarModels(this.car.mark);

        this.mark = this.car.mark;
        this.model = this.car.model
        this.branch = this.car.branch;
        this.type = this.car.type;
        this.origin = this.car.origin;
        this.gearbox = this.car.specification.gearbox;
        this.fuel = this.car.specification.fuel;
        this.color = this.car.specification.color;
        this.transmission = this.car.specification.transmission;
        this.client = this.car.owner;

        this.form = new Form({
          mark_id: this.car.mark.id,
          model_id: this.car.model.id,
          branch_id: this.car.branch.id,
          owner_type: this.car.owner_type,
          location: this.car.location,
          type_id: this.car.type.id,
          origin_id: this.car.origin ? this.car.origin.id : null,
          year: this.car.year,
          reg_number: this.car.specification.reg_number,
          vin_code: this.car.specification.vin_code,
          gearbox_id: this.car.specification.gearbox.id,
          fuel_id: this.car.specification.fuel.id,
          color_id: this.car.specification.color.id,
          transmission_id: this.car.specification.transmission.id,
          engine: this.car.specification.engine,
          capacity: this.car.specification.capacity,
          race: this.car.specification.race,
          price: this.car.price,
          price_for_owner: this.car.price_for_owner,
          contract_type: this.car.contract_type,
          minimum_price: this.car.minimum_price,
          old_price: this.car.old_price,
          credit_percent: this.car.credit_percent,
          autoria_id: this.car.autoria_id,
          olx_id: this.car.olx_id,
          is_top_price: this.car.is_top_price,
          is_checked: this.car.is_checked,
          is_new: this.car.is_new,
          is_our: this.car.is_our,
          tech_passport: this.car.tech_passport,
          damage_link: this.car.links ? this.car.links.damage_link : null,
          old_ads_link: this.car.links ? this.car.links.old_ads_link : null,
          client_id: this.car.owner ? this.car.owner.id : null,
          client_name: null,
          client_phone: null,
          client_address: null,
          client_driver_license: null,
        });
      } catch (error) {
        errorHandler(error);
      }
    },

    async getCarModels(mark) {
      try {
        this.onProgressCarModels = true;

        this.models = [];
        this.model = null;

        const models_response = await API.apiClient.get(
            '/cars/mark/' + mark.id + '/models',
            {
              params: {
                is_paginated: 0,
                sorting: 'asc',
                sort_column: 'name',
              }
            }
        );

        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressCarModels = false;
    },

    async editCar() {
      await this.form.put('/cars/edit/' + this.carId).then(() => {
        showSuccess();
        setTimeout(() => this.$router.push('/cars'), 600);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  }
}
</script>

<style scoped></style>
